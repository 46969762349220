h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Libre Baskerville', serif;
  line-height: 1.25;
  margin: 0
}

/* Use span or div if you don't need this spacing */
* + h1,
* + h2,
* + h3,
* + h4,
* + h5,
* + h6,
* + ul,
* + ol,
* + p {
  margin-top: 1em;
}

.text-accent {
  font-family: 'The Nautigal', cursive;
  font-weight: bold;
}

.font-expanded {
  font-stretch: expanded;
}

.text-responsive {
  font-size: clamp(100%, 3vw, 150%);
}